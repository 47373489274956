import { PROFILE_NAMES } from "../constants/profiles.constants"

export function getProfileName(profileName: string){
    switch(profileName){
        case PROFILE_NAMES.AMBASSADOR_ONE:
            return  'Embajador Nivel 1'
        case PROFILE_NAMES.AMBASSADOR_TWO:
            return 'Embajador Nivel 2'
        case PROFILE_NAMES.AMBASSADOR_THREE:
            return 'Embajador Nivel 3'
        case PROFILE_NAMES.AMBASSADOR_FOUR:
            return 'Embajador Nivel 4'
    }
}

export function isAmbassador(profileName: string){
    if (profileName === PROFILE_NAMES.AMBASSADOR_ONE || 
        profileName === PROFILE_NAMES.AMBASSADOR_TWO ||
        profileName === PROFILE_NAMES.AMBASSADOR_THREE ||
        profileName === PROFILE_NAMES.AMBASSADOR_FOUR)
    return true
    else return false
}

export function ambassadorTitle(profileName: string){
    if (isAmbassador(profileName))
    return 'Embajador'
    else return 'Referidor'
}