
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
/* VUELIDATE */
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
/* STORE */
import { remittance } from '@/store/namespaces';
import RemittanceMethods from '@/store/remittance/methods/remittance.methods';
import { MinAmount } from '@/modules/remittance/interfaces/minAmount.interface';
import { showToast } from '@/utils/global-functions';
/* INTERFACES */

@Component({
    mixins: [validationMixin],
    validations:{
        chargeFee:{
            isValid: function (value) {
                    return (value <= this.maxAmount && value >= this.availableMinAmount)
            },
        }
    }
})
export default class ChargeFee extends Vue {
    @Prop() showModal!: {
        show:boolean, 
        country: string, 
        availableAmount: number, 
        iso_code: string
        id_country: number,
        id_currency: number
    };
    show: boolean = false;
    chargeFee = 0;
    maxAmount = 1;

/* MODAL */
    @Watch('showModal.show')
     renderModal(){
        this.$v.$reset();
        this.chargeFee = 0;
        this.show = this.showModal.show;
        this.maxAmount = this.showModal.availableAmount

    }

    @Watch('show')
    sendToParent(newVal: boolean){
        this.$emit('changeModalStatus', newVal)
    }
/* END MODAL */

    sendChatMessage(){
            this.$v.$touch();
            if (!this.$v.$invalid){
                this.$router.push({name: 'Chat', params:{message: `Hola, quiero cobrar ${this.chargeFeeFormatted} ${this.showModal.iso_code} CriptoMillas de ${this.showModal.country}`}}).catch();
            }
    }

    get chargeFeeFormatted(){
        return this.$options.filters.currency(this.chargeFee)
    }

    get availableAmountFormatted(){
        return this.$options.filters.currency(this.showModal.availableAmount) + ` ${this.showModal.iso_code}`
    }

    get availableMinAmountFormatted(){
        let minAmount = this.minAmounts.find(el=> el.id_country_origin === this.showModal.id_country && el.id_currency_origin === this.showModal.id_currency)
        if (minAmount)
            return this.$options.filters.currency(minAmount.min_amount) + ` ${this.showModal.iso_code}`
        else return '0,00'
    }

    get availableMinAmount(){
        let minAmount = this.minAmounts.find(el=> el.id_country_origin === this.showModal.id_country && el.id_currency_origin === this.showModal.id_currency)
        if (minAmount)
            return minAmount.min_amount
        else return 0.01
    }

    @remittance.Getter(RemittanceMethods.getters.GET_MIN_AMOUNTS)
    minAmounts!: MinAmount[];
}

