
import { Component, Vue, Watch } from 'vue-property-decorator';
/* COMPONENTS */
import SvgIcon from '@/components/utilities/SvgIcon.vue';
import Table from '@/components/tables/Table.vue';
import DataCard from '@/components/utilities/DataCard.vue';
import TermsPopup from '@/modules/auth/components/TermsPopup.vue';
import ChargeFeePopup from '@/modules/ambassador/components/ChargeFeePopup.vue';
import Loader from '@/components/utilities/Loader.vue'
/* FUNCTIONS */
import {getFiveColors} from '@/modules/charts/functions/chart.functions';
import { ambassadorLinkAvailable, getAmbassadorLevelColor, getReferralStatus, getReferralStatusName } from '../functions/ambassador.functions';
import { ambassadorTitle, getProfileName, isAmbassador } from '@/modules/client/functions/profile.functions';
import { capitalize, showToast } from '@/utils/global-functions';
/* STORE */
import { auth, ambassadors, remittance, notifications } from '@/store/namespaces';
import AuthMethods from '@/store/auth/methods/auth.methods';
import AmbassadorsMethods from '@/store/ambassadors/methods/ambassadors.methods';
import RemittanceMethods from '@/store/remittance/methods/remittance.methods';
import NotifMethods from '@/store/notifications/methods/verificationLevels.methods'
/* INTERFACES */
import { User } from '@/modules/auth/interfaces/user.interface';
import { ReferralOperation } from '../interfaces/referralOperation.interface';
import { ReferralsByCountry, ReferralsByStatus } from '../interfaces/reports.interfaces';
import { CryptoMile } from '../interfaces/cryptomile.interface';
import { Notif } from '@/modules/notifications/interfaces/notification.interface';
/* CONSTANTS */
import { REFERRAL_SRATUS, REFERRAL_SRATUS_ARRAY } from '../constants/ambassadot.constants';

@Component({
    components: {
        SvgIcon,
        Table,
        DataCard,
        TermsPopup,
        ChargeFeePopup,
        Loader
    },
})
export default class Ambassador extends Vue {
    loading = true;
    $refs : any = {}
    textFilter : string = ''
    currentFilter : any = {}
    currentOrderFilter : Array<any> = []
    currentView : any = []
    headerFilters: Array<any> = []
    editable: boolean = true;
    selectedItemId : any = '';
    tabIndex : number = 0;
    termsModal = false;
    chargeFeeModal ={
        show: false,
        country: null,
        availableAmount: null,
        iso_code: null,
        id_country: null,
        id_currency: null
    }
    /* TABLE */
    isBusy = false;
    perPageOperations : number = 7;
    perPageFee : number = 7;
    perPageUsers : number = 7;
    currentPageOperations: number = 1;
    currentPageFee: number = 1;
    currentPageUsers: number = 1;
    fields = [{key: 'cust_cr_cod_pub', label:'CR-ID'},{key: 'first_name', label:'Nombre'},{key: 'name_country', label:'País'},{key: 'referral_status', label:'Estatus'}]
    feeFields = [{key:'viewing_name',label:'Pais'},{key:'credits',label:'Com. Generada'},{key:'debits',label:'Com. Cobrada'},{key:'available_cryptomile',label:'Com. Disponible'},{key:'action',label:''}]
    opFields = [{key: 'id_operation', label: 'ID-Operación'},{key: 'operation_amount', label: 'Monto Origen'},{key: 'percent_fee', label: 'Comision %'},{key: 'amount', label: 'Comisión FIAT'}]
    referralStatus : Array<any>=REFERRAL_SRATUS_ARRAY;
    chartSeries2= [44, 55, 41, 17, 15]
    chartOptions2= {
            chart: {
            type: 'donut',
            },
            legend:{
                show: false,
            },
            dataLabels: {
            enabled: false
            },
            //colors: ['#93BB97', '#F2B646','#88C9CE', '#F48799','#5C99E7', '#F2ECE3', ],
            // colors: ['#F0B076','#78B9B5','#FCE8A4','#D7657A','#848FDF'],
            colors:['#334D5C','#45B29D','#EFC94C','#E27A3F','#DF4949'],
            // colors:['#351C4D','#F5AB99','#FEB47B','#FF7E5F','#765285'],
            labels: ['Chile', 'Perú', 'Argentina', 'Colombia','Rep. Dom.']
    }
    chartSeries1= [44, 55, 41]
    chartOptions1= {
        chart: {
        type: 'donut',
        },
        legend:{
            show: false,
        },
        dataLabels: {
        enabled: false
        },
        //colors: ['#93BB97', '#F2B646','#88C9CE', '#F48799','#5C99E7', '#F2ECE3', ],
        // colors: ['#F0B076','#78B9B5','#FCE8A4','#D7657A','#848FDF'],
        colors:['#334D5C','#45B29D','#EFC94C','#E27A3F','#DF4949'],
        // colors:['#351C4D','#F5AB99','#FEB47B','#FF7E5F','#765285'],
        labels: ['Ausentes', 'Activos', 'Inactivos']
    }
    

    showCopyLinkTooltip = false;

    async mounted(){
        try{
            if (this.ambassadorLinkAvailable){
                if (!await this.fetchReferrals({cust_cr_cod_pub: this.userData.cust_cr_cod_pub})
                    || !await this.fetchReferralsByCountry(this.userData.email_user)
                    || !await this.fetchReferralsByStatus(this.userData.email_user)
                    || !await this.fetchCryptoMiles(this.userData.email_user)
                    || !await this.fetchReferralsOperations(this.userData.email_user)
                    || !await this.fetchMinAmounts()
                    ){
                throw new Error()
                }else{
                    this.setReferralsCountries()
                    this.setReferralsStatus()
                }
            }
            this.loading = false;
        }catch(e){
            this.$router.push({name: 'ErrorPage'}).catch();
        }finally{
        }
    }

    get ambassadorLinkAvailable(){
        return ambassadorLinkAvailable(this.userData)
    }

    setReferralsCountries(){
        let labels = this.referralsByCountries.map(a => a.viewing_name);
        let series = this.referralsByCountries.map(a => a.total_referrals);
        this.chartOptions2.labels = labels;
        this.chartSeries2 = series;
    }

    setReferralsStatus(){
        let labels = this.referralsByStatus.map(a => this.getReferralStatusName(a.referral_status));
        let series = this.referralsByStatus.map(a => a.total_referrals);
        this.chartOptions1.labels = labels;
        this.chartSeries1 = series;
    }

    copyLink() {
    this.$copyText('¡Envía dinero a tus seres queridos con Bithonor! Es rápido, seguro y con las mejores tasas. Regístrate con mi enlace y participa por premios y beneficios todos los meses '+this.ambassadorLink).then(function (e) {}, function (e) {})
    this.showCopyLinkTooltip = true;
    setTimeout(()=>{this.showCopyLinkTooltip = false}, 1000)
    }

    capitalize(name: string){
        return capitalize(name)
    }

    getBadgeColor(j: number): number{
        return getFiveColors(j)
    }

    get ambassadorLevel(){
        return getAmbassadorLevelColor(1)
    }

    get isAmbassador(){
        return isAmbassador(this.userData.name_profile)
    }

    get getProfileName(){
        return getProfileName(this.userData.name_profile)
    }

    get title(){
        return ambassadorTitle(this.userData.name_profile)
    }

    getReferralStatus(status: string){
        return getReferralStatus(status)
    }

    getReferralStatusName(status: string){
        return getReferralStatusName(status)
    }

    /* MODAL */
    changeTermsModalStatus(newVal: boolean) {
        this.termsModal = newVal;
    }
    changeChargeFeeModalStatus(newVal: boolean) {
        this.chargeFeeModal.show = newVal;
    }
    /* END MODAL */

    async sendRequest(){
        this.$refs.loader.showLoader();
        if (await this.sendAmbassadorRequest(this.userData.email_user))
            this.$router.push({name: 'AmbassadorRequestSuccess'}).catch();
        else
            showToast('Ha ocurrido un error enviando su solicitud','error')
        this.$refs.loader.hideLoader();
    }

    /* AUTH */
    @auth.Getter(AuthMethods.getters.GET_AMBASSADOR_LINK)
    ambassadorLink!: string;
    @auth.Getter(AuthMethods.getters.GET_USER_DATA)
    userData!: User;
    /* AMBASSADORS */
    @ambassadors.Action(AmbassadorsMethods.actions.FETCH_REFERRALS)
    fetchReferrals!: (data: any) => Promise<boolean>
    @ambassadors.Getter(AmbassadorsMethods.getters.GET_REFERRALS)
    referralsInfo!: {total_referrals: number, referrals: User[]}
    @ambassadors.Action(AmbassadorsMethods.actions.FETCH_REFERRALS_OPERATIONS)
    fetchReferralsOperations!: (email_user: string) => Promise<boolean>
    @ambassadors.Getter(AmbassadorsMethods.getters.GET_REFERRALS_OPERATIONS)
    referralsOperations!: {total_operations: number, operations: ReferralOperation[]}
    @ambassadors.Action(AmbassadorsMethods.actions.FETCH_REFERRALS_BY_COUNTRIES)
    fetchReferralsByCountry!: (email_user: string) => Promise<boolean>
    @ambassadors.Getter(AmbassadorsMethods.getters.GET_REFERRALS_BY_COUNTRIES)
    referralsByCountries!: ReferralsByCountry[]
    @ambassadors.Action(AmbassadorsMethods.actions.FETCH_REFERRALS_BY_STATUS)
    fetchReferralsByStatus!: (email_user: string) => Promise<boolean>
    @ambassadors.Getter(AmbassadorsMethods.getters.GET_REFERRALS_BY_STATUS)
    referralsByStatus!: ReferralsByStatus[]
    @ambassadors.Action(AmbassadorsMethods.actions.SEND_AMBASSADOR_REQUEST)
    sendAmbassadorRequest!: (email_user: string) => Promise<boolean>
    @ambassadors.Action(AmbassadorsMethods.actions.FETCH_CRYPTOMILES)
    fetchCryptoMiles!: (email_user: string) => Promise<boolean>
    @ambassadors.Getter(AmbassadorsMethods.getters.GET_CRYPTOMILES)
    cryptoMiles!: CryptoMile[];
    @remittance.Action(RemittanceMethods.actions.FETCH_MIN_AMOUNTS)
    fetchMinAmounts!: () => Promise<boolean>
    /* NOTIFICATIONS */
    @notifications.Getter(NotifMethods.getters.GET_MARQUEE_NOTIFICATIONS)
    marqueeNotifications!: Notif[];

}
